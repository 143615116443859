'use client'
import { BiSearch } from 'react-icons/bi'
import type { InputGroupProps, InputProps } from '@chakra-ui/react'
import { HStack, InputRightElement, Input, InputGroup, forwardRef, Spinner } from '@chakra-ui/react'
import { useCallback, useRef } from 'react'
import { mergeRefs } from '@chakra-ui/react-utils'
import { GrandIconButton } from 'ui'
import { MdClose } from 'react-icons/md'

export type SearchInputProps = InputProps & {
  isLoading?: boolean
  inputGroupProps?: InputGroupProps
  searchQuery?: string
  onClear?: () => void
  onClickSearch?: () => void
}

const SearchInput = forwardRef<SearchInputProps, 'input'>(
  (
    { isLoading, searchQuery, onClear, onClickSearch: _onClickSearch, inputGroupProps, ...props },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    let inputPaddingRight = searchQuery?.length ? 63 : 30
    inputPaddingRight = isLoading ? inputPaddingRight + 25 : inputPaddingRight

    const onClearSearch = useCallback(() => {
      onClear?.()
      inputRef.current?.focus()
    }, [onClear])

    const onClickSearch = useCallback(() => {
      if (!searchQuery) {
        inputRef.current?.focus()
        return
      }

      _onClickSearch?.()
    }, [_onClickSearch, searchQuery])

    return (
      <InputGroup
        borderColor="brand.50"
        borderWidth="1px"
        minW="20vw"
        size="sm"
        w="auto"
        {...inputGroupProps}>
        <Input
          _focus={{
            borderColor: 'secondary'
          }}
          bg="background.default"
          pr={`${inputPaddingRight}px`}
          ref={mergeRefs(ref, inputRef)}
          {...props}
        />

        <InputRightElement bg="transparent" border="none" p={0} w="auto">
          <HStack gap={1} h="100%">
            {isLoading ? <Spinner color="text.default" mr={2} opacity="0.7" size="xs" /> : null}

            {!!searchQuery?.length && onClear ? (
              <GrandIconButton
                aria-label="clear search"
                bg="transparent"
                boxShadow="none"
                color="text.default"
                fontSize="md"
                h="100%"
                icon={<MdClose />}
                onClick={onClearSearch}
                px="1"
                size="xs"
              />
            ) : null}

            <GrandIconButton
              aria-label="search"
              bg="transparent"
              boxShadow="none"
              color="text.default"
              fontSize="md"
              h="100%"
              icon={<BiSearch />}
              onClick={onClickSearch}
              px="2"
              size="xs"
            />
          </HStack>
        </InputRightElement>
      </InputGroup>
    )
  }
)

SearchInput.displayName = 'SearchInput'

export default SearchInput
