'use client'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'
import { Box, Button, Divider, Flex, HStack, SimpleGrid, VStack } from '@chakra-ui/react'
import { MdProductionQuantityLimits } from 'react-icons/md'
import { AiFillFilter } from 'react-icons/ai'
import type { IGrandSection } from 'ui'
import { EmptyMessage, ActivityLoader, GrandSection } from 'ui'
import type { CartItem, DeliveryDTO, ProductLightDTO } from 'ecosystem'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { useOnScreen } from 'ui/hooks'
import { overrideText } from 'ui/lib/overrides'
import type { SearchProductsState } from 'shared-redux/state/slices/searchProducts'
import ProductListItemComponent from '../ProductListItem'
import type { ProductListItemProps } from '../ProductListItem'
import type { ProductListSearchMoreFiltersProps } from './ProductListSearchMoreFilters'
import { ProductListSearchMoreFilters } from './ProductListSearchMoreFilters'

export interface ProductListSearchProps extends IGrandSection {
  items: ProductLightDTO[]
  totalItems: number
  deliveryOptions: DeliveryDTO[] | null
  searchProductsState: SearchProductsState
  // sortingOptions: SortingOption[]
  onAddToCart: (cartItem: CartItem) => void
  onMore: () => void
  title?: string
  isLoading?: boolean
  onProductRequest: (item: ProductLightDTO) => void
  infiniteScrollConfig: {
    loadMore: () => void
    shouldLoadMore: boolean
  }
  productsUrlPath: string
  customComponents?: {
    ProductListItem?: FC<ProductListItemProps>
  }
  filterProps: Pick<
    ProductListSearchMoreFiltersProps,
    | 'updateCategoryFilterItemSelected'
    | 'updateBrandFilterItemSelected'
    | 'updateSelectedPriceRange'
    | 'updateOptionFilterItemSelected'
    | 'updateSearchDelayed'
    | 'clearFilters'
    | 'isLoading'
    | 'onSubmit'
    | 'showSubmit'
  >
}

export interface ProductListSearchOverrides {
  sort?: string
  productListSearchTitle?: string
  viewTotalProducts?: string
  noProductsFound?: string
}

export const ProductListSearch: FC<ProductListSearchProps> = ({
  isLoading,
  title,
  items,
  totalItems,
  deliveryOptions,
  infiniteScrollConfig,
  searchProductsState,
  onMore,
  onAddToCart,
  onProductRequest,
  productsUrlPath,
  customComponents,
  filterProps,
  ...props
}) => {
  const overrides = useOverridesContext<keyof ProductListSearchOverrides>()

  // ******** INFINITE SCROLL ********

  const loadMoreIntersectionDiv = useRef<HTMLDivElement | null>(null)
  const isLastElementReached = useOnScreen(loadMoreIntersectionDiv)
  const { shouldLoadMore, loadMore } = infiniteScrollConfig

  useEffect(() => {
    if (isLastElementReached && shouldLoadMore && !isLoading) {
      loadMore()
    }
  }, [isLastElementReached, isLoading, shouldLoadMore, loadMore])

  const { ProductListItem = ProductListItemComponent } = customComponents || {}

  return (
    <>
      <GrandSection
        contentContainerProps={{
          spacing: 4
        }}
        {...{ title }}
        {...props}>
        <VStack
          align={{
            base: 'flex-start',
            lg: 'center'
          }}
          justify="flex-start">
          <Button
            aria-label="show-filters"
            bg="background.mildDark"
            borderColor="background.mildDark"
            borderRadius="md"
            display={{ base: 'block', md: 'none' }}
            minH="35px"
            minW={100}
            onClick={onMore}
            size="sm"
            w={{ base: 'full', sm: 'auto' }}>
            <HStack justify="center" w="full">
              <AiFillFilter />
              <Box>{overrideText('Sortera och filtrera', overrides?.productListSearchTitle)}</Box>
            </HStack>
          </Button>
        </VStack>

        <Divider />

        <HStack align="flex-start" w="full">
          <ProductListSearchMoreFilters
            display={{ base: 'none', md: 'flex' }}
            flex={3}
            pt={1}
            w="auto"
            {...{
              ...filterProps,
              searchProductsState
            }}
          />

          <Box flex={9} minH={500} position="relative">
            {!!items.length && (
              <VStack w="full">
                <HStack justify="space-between" w="full">
                  <Flex
                    alignItems="center"
                    color="text.mild"
                    fontSize="xs"
                    fontWeight="medium"
                    textAlign={{ base: 'right', md: 'left' }}>
                    {`${overrideText(
                      `visar produkter`,
                      overrides?.viewTotalProducts
                    )}: ${totalItems}`}
                  </Flex>
                </HStack>

                <SimpleGrid
                  columns={{
                    base: 2,
                    md: 4
                  }}
                  gap={2}
                  spacing={{
                    base: 1,
                    md: 6
                  }}
                  width="100%">
                  {items.map((item) => (
                    <ProductListItem
                      showsPrice
                      key={item.id}
                      onBuy={() => {
                        onAddToCart({ ...item, qty: '1' })
                      }}
                      {...{ item, deliveryOptions, onProductRequest, productsUrlPath }}
                    />
                  ))}
                </SimpleGrid>

                {!!isLoading && (
                  <ActivityLoader
                    fontSize="xs"
                    h="full"
                    position="relative"
                    spinnerProps={{ size: 'sm' }}
                    text=""
                    w="full"
                    zIndex={0}
                  />
                )}
              </VStack>
            )}

            {isLoading && !items.length ? (
              <ActivityLoader
                fontSize="xs"
                h="full"
                position="relative"
                spinnerProps={{ size: 'sm' }}
                text=""
                w="full"
                zIndex={0}
              />
            ) : null}

            {!items.length && !isLoading && (
              <Flex align="center" h={500} justify="center">
                <EmptyMessage
                  fontSize="xl"
                  icon={<MdProductionQuantityLimits />}
                  text={overrideText('Inga produkter hittades', overrides?.noProductsFound)}
                />
              </Flex>
            )}
          </Box>
        </HStack>
      </GrandSection>

      <Box h="1px" ref={loadMoreIntersectionDiv} w="full" />
    </>
  )
}
