'use client'
import type { MouseEventHandler } from 'react'
import { useMemo } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { Box, VStack } from '@chakra-ui/react'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { overrideText } from 'ui/lib/overrides'
import type { StorefrontSearchItem } from '../types'
import { SearchResultsItem } from './SearchResultsItem'

type SearchResultsProps = StackProps & {
  searchPagePath: string
  items?: StorefrontSearchItem[] | null
  onClickResultItem?: MouseEventHandler<HTMLLinkElement>
  maxLength?: number
  searchQuery?: string
}

interface SearchResultsOverrides {
  searchPopularResults: string
}

export const SearchResults = ({
  items,
  onClickResultItem,
  maxLength,
  searchQuery,
  searchPagePath,
  ...props
}: SearchResultsProps) => {
  const overrides = useOverridesContext<keyof SearchResultsOverrides>()

  const filteredItems = useMemo(() => {
    if (!items) {
      return null
    }

    if (typeof maxLength !== 'number' || maxLength >= items.length) {
      return items
    }

    return [...items.slice(0, maxLength)]
  }, [items, maxLength])

  if (!filteredItems) {
    return null
  }

  return (
    <VStack
      alignItems="stretch"
      bg="background.default"
      className="search-results"
      color="text.default"
      spacing={0}
      {...props}>
      <SearchResultsItem
        item={{
          id: 'all products',
          type: 'SEARCH',
          slug: `${searchPagePath}${searchQuery ? `?q=${searchQuery}` : ''}`,
          text: `${searchQuery}`
        }}
        onClick={onClickResultItem as unknown as MouseEventHandler<HTMLDivElement>}
      />

      {!!filteredItems.length && (
        <>
          <Box color="text.darkGrey" px={6} py={4}>
            {overrideText('Populära sökresultat:', overrides?.searchPopularResults)}
          </Box>

          <VStack alignItems="stretch" spacing={0}>
            {filteredItems.map((item) => (
              <SearchResultsItem
                item={item}
                key={item.id}
                onClick={onClickResultItem as unknown as MouseEventHandler<HTMLDivElement>}
              />
            ))}
          </VStack>
        </>
      )}
    </VStack>
  )
}
