import type { FC } from 'react'
import { useMemo } from 'react'
import { VStack } from '@chakra-ui/react'
import type { SearchBrandFacetDTO } from 'ecosystem'
import CheckboxFilterItem from './CheckboxFilterItem'

interface IFilterOptionsProps {
  selectedOptions: string[]
  options: SearchBrandFacetDTO[]
  onChange: (props: { id: string; isSelected: boolean }) => void
}

type FilterOptionItem = SearchBrandFacetDTO & {
  isChecked?: boolean
}

const FilterOptions: FC<IFilterOptionsProps> = ({ onChange, selectedOptions, options }) => {
  const items = useMemo(() => {
    const selected: FilterOptionItem[] = []
    const unselected: FilterOptionItem[] = []

    options.forEach((option) => {
      if (selectedOptions.includes(option.id)) {
        selected.push({
          ...option,
          isChecked: true
        })
      } else {
        unselected.push({
          ...option,
          isChecked: false
        })
      }
    })

    return [...selected, ...unselected]
  }, [options, selectedOptions])

  return (
    <VStack>
      {items.map(({ id, name, count }, index) => {
        return (
          <CheckboxFilterItem
            count={count}
            id={id}
            isChecked={selectedOptions.includes(id)}
            // eslint-disable-next-line react/no-array-index-key -- id can be repeated
            key={`${id}-${index}`}
            name={name}
            onChange={(e) => {
              onChange({
                id,
                isSelected: e.target.checked
              })
            }}
            value={name}
          />
        )
      })}
    </VStack>
  )
}

export default FilterOptions
