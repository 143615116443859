import type {
  ProductSearchFacets,
  ProductSearchResponseDTO,
  StoreSearchProductFilterDTO
} from 'ecosystem'
import type { TProductSearchSelectedFilters } from 'shared-redux/state/slices/searchProducts'

export const createFilterDto = ({
  facets,
  searchQuery
}: {
  facets?: ProductSearchFacets
  searchQuery: string
}): StoreSearchProductFilterDTO => {
  const brandIds = facets?.brands.map(({ id }) => id)
  const categoryIds = facets?.categories.map(({ id }) => id)
  const optionIds = facets?.options.map((group) => group.values.map(({ id }) => id))

  return {
    brandIds,
    categoryIds,
    optionIds,
    text: searchQuery
  }
}

export const createFilterDtoFromSelectedFilters = ({
  selectedFilters,
  searchQuery
}: {
  selectedFilters: Partial<TProductSearchSelectedFilters>
  searchQuery: string
}): StoreSearchProductFilterDTO => {
  const { minPrice, maxPrice } = selectedFilters
  const brandIds = selectedFilters.brands
  const categoryIds = selectedFilters.categories
  const optionIds = selectedFilters.options ? Object.values(selectedFilters.options) : undefined

  return {
    brandIds,
    categoryIds,
    optionIds,
    text: searchQuery,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- it's number
    ...(Number.isInteger(minPrice) ? { minPrice: minPrice! } : {}),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- it's number
    ...(Number.isInteger(maxPrice) ? { maxPrice: maxPrice! } : {})
  }
}

const getNumberFromQuery = (value: string | string[] | undefined): number | undefined => {
  const newValue = Number(Array.isArray(value) ? value[0] : value)

  return Number.isNaN(newValue) ? undefined : newValue
}

export const getFiltersFromQuery = ({
  query: _query = {},
  res
}: {
  query: Record<string, string | string[] | undefined> | undefined
  res: ProductSearchResponseDTO
}) => {
  const { minPrice, maxPrice, ...query } = _query
  const newMinPrice = getNumberFromQuery(minPrice)
  const newMaxPrice = getNumberFromQuery(maxPrice)

  const selectedFilters = Object.entries(query).reduce<Partial<TProductSearchSelectedFilters>>(
    (acc, [key, value]) => {
      if (typeof value === 'undefined') {
        return acc
      }

      if (key === 'minPrice') {
        acc.minPrice = getNumberFromQuery(value)

        return acc
      }

      if (key === 'maxPrice') {
        acc.maxPrice = getNumberFromQuery(value)

        return acc
      }

      if (key === 'brandIds') {
        const filteredValues = (Array.isArray(value) ? value : [value]).filter((valueId) =>
          res.facets.brands?.some((brand) => brand.id === valueId)
        )

        if (filteredValues.length) {
          acc.brands = filteredValues
        }

        return acc
      }

      if (key === 'categoriesIds') {
        const filteredValues = (Array.isArray(value) ? value : [value]).filter((valueId) =>
          res.facets.categories?.some((category) => category.id === valueId)
        )

        if (filteredValues.length) {
          acc.categories = filteredValues
        }

        return acc
      }

      if (res.facets.options?.length) {
        const option = res.facets.options.find((_option) => _option.name === key)

        if (!option) {
          return acc
        }

        const filteredValues = (Array.isArray(value) ? value : [value]).filter((valueId) =>
          option.values.some((item) => item.id === valueId)
        )

        if (filteredValues.length) {
          acc.options = {
            ...acc.options,
            [key]: filteredValues
          }
        }

        return acc
      }

      return acc
    },
    {}
  )

  return {
    selectedFilters: {
      ...selectedFilters,
      minPrice: newMinPrice,
      maxPrice: newMaxPrice
    }
  }
}
