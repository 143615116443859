'use client'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { Box, Popover, PopoverAnchor, PopoverContent, Portal } from '@chakra-ui/react'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import type { UseSearchReturnType } from '../hooks/useSearch'
import { SearchResults } from './SearchResults'
import type { SearchInputProps } from './SearchInput'
import SearchInput from './SearchInput'
import { SearchButton } from './SearchButton'

interface SearchComponentProps extends UseSearchReturnType {
  onSearch: () => void
  inputProps?: SearchInputProps
  searchPagePath: string
}

export interface SearchComponentOverrides {
  searchPlaceholder?: string
}

export const SearchComponent: FC<SearchComponentProps> = ({
  onSearch,
  preservedData,
  onKeyDown,
  inputRef,
  searchQuery,
  clearSearch,
  isLoading,
  submitAdvancedSearch,
  advancedSearchQuery,
  setAdvancedSearchQuery,
  searchPagePath
}) => {
  const overrides = useOverridesContext<keyof SearchComponentOverrides>()

  const [isFocused, setIsFocused] = useState(false)
  const isOpenPopover =
    isFocused && !!preservedData && (!advancedSearchQuery || searchQuery !== advancedSearchQuery)

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      const isSearchResult =
        !!(event.target as Element | null)?.closest('.search-results') ||
        !!(event.target as Element | null)?.closest('.search-input-wrapper')

      if (!isSearchResult) {
        setIsFocused(false)
      }
    }

    document.addEventListener('click', handler)

    return () => {
      document.removeEventListener('click', handler)
    }
  }, [])

  return (
    <>
      <Box className="search-input-wrapper" display={{ base: 'none', md: 'initial' }}>
        <Popover autoFocus={false} isOpen placement="bottom-start">
          <PopoverAnchor>
            <Box>
              <SearchInput
                _focus={{
                  border: 'none'
                }}
                _placeholder={{
                  fontSize: 'xs'
                }}
                isLoading={isLoading}
                onBlur={(event) => {
                  const element = event.relatedTarget
                  let isSearchResult = false

                  // bug in Safari; When click on search result, relatedTarget in Safari is always 'popover-content'
                  if (element?.id.startsWith('popover-content')) {
                    isSearchResult = !!element.querySelector('.search-results')
                  } else {
                    isSearchResult = !!element?.closest('.search-results')
                  }

                  if (!isSearchResult) {
                    setIsFocused(false)
                    setAdvancedSearchQuery('')
                  }
                }}
                onClear={clearSearch}
                onClickSearch={submitAdvancedSearch}
                onFocus={() => {
                  setIsFocused(true)
                }}
                onKeyDown={onKeyDown}
                placeholder={overrideText(
                  'Sök efter produkt eller tjänst',
                  overrides?.searchPlaceholder
                )}
                ref={inputRef}
                searchQuery={searchQuery}
              />
            </Box>
          </PopoverAnchor>

          <Portal>
            <PopoverContent
              minWidth="xs"
              rootProps={{ zIndex: 9001, display: isOpenPopover ? 'initial' : 'none' }}
              width="auto">
              <SearchResults
                borderRadius="md"
                boxShadow="md"
                items={preservedData}
                maxLength={5}
                onClickResultItem={() => {
                  setIsFocused(false)
                }}
                searchPagePath={searchPagePath}
                searchQuery={searchQuery}
              />
            </PopoverContent>
          </Portal>
        </Popover>
      </Box>

      <Box display={{ base: 'initial', md: 'none' }}>
        <SearchButton {...{ onSearch }} />
      </Box>
    </>
  )
}
