import { BiSearch } from 'react-icons/bi'
import type { FC } from 'react'
import { GrandIconButton } from 'ui'
import { Icon } from '@chakra-ui/react'

// is used for desktop view to open SearchDesktopSlideOut

interface SearchButtonProps {
  onSearch: () => void
}

export const SearchButton: FC<SearchButtonProps> = ({ onSearch }) => {
  return (
    <GrandIconButton
      alignItems="center"
      aria-label="search"
      bg="none"
      color="text.light"
      fontSize="2xl"
      icon={<Icon as={BiSearch} color="text.default" />}
      justifyContent="center"
      onClick={onSearch}
      shadow="none"
      size="sm"
    />
  )
}
