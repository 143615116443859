import type { FC } from 'react'
import type { CheckboxProps } from '@chakra-ui/react'
import { Checkbox, HStack } from '@chakra-ui/react'
import { GrandText } from 'ui'

interface ICheckboxFilterItemProps extends CheckboxProps {
  id: string
  count?: string | number
}

const CheckboxFilterItem: FC<ICheckboxFilterItemProps> = ({ id, count, ...props }) => {
  return (
    <Checkbox
      borderColor="primary"
      borderRadius="md"
      size="lg"
      sx={{
        '.chakra-checkbox__label': {
          flex: 1
        }
      }}
      w="100%"
      {...{ id }}
      {...props}>
      <HStack fontSize="sm" justifyContent="space-between" spacing={1} width="100%">
        <GrandText w="auto">{props.value || 'no value'}</GrandText>

        {!!String(count) && <GrandText w="auto">{count}</GrandText>}
      </HStack>
    </Checkbox>
  )
}

export default CheckboxFilterItem
