'use client'
import { useDispatch, useSelector } from 'shared-redux'
import { useCallback, useEffect, useMemo } from 'react'
import type { SearchProductsState } from 'shared-redux/state/slices/searchProducts'
import {
  addProducts,
  initSearchProducts,
  resetSearchProducts,
  selectSearchProductsInitStatus,
  selectSearchProductsPage,
  selectSearchProductsProducts,
  selectSearchProductsSelectedFilters,
  selectSearchProductsTotalElements,
  setDefaultFilters,
  setInitStatus,
  setNextPage,
  setProducts,
  updateAvailableFilters,
  updateSelectedFiltersFromQuery
} from 'shared-redux/state/slices/searchProducts'
import type { ProductLightDTO } from 'ecosystem'
import type { GetSearchProductEndpointPayload } from 'shared-redux/state'
import { useLazyGetSearchProductsQuery } from 'shared-redux/state'
import { PAGE_SIZE } from '../../constants'
import { createFilterDto, createFilterDtoFromSelectedFilters, getFiltersFromQuery } from './utils'

interface HookReturnType {
  products: ProductLightDTO[]
  totalElements: number
  isNextPageAvailable: boolean
  loadMore: () => void
  isLoading: boolean
  initStatus: SearchProductsState['initStatus']
}

export const useProductListSearch = ({
  query,
  pageSize = PAGE_SIZE
}: {
  query: Record<string, string | string[] | undefined> | undefined
  pageSize?: number
}): HookReturnType => {
  const searchQuery = (query?.q as string) || ''
  const dispatch = useDispatch()

  const [fetchProducts, { isFetching: isLoading }] = useLazyGetSearchProductsQuery()

  const products = useSelector(selectSearchProductsProducts)
  const totalElements = useSelector(selectSearchProductsTotalElements)
  const page = useSelector(selectSearchProductsPage)
  const initStatus = useSelector(selectSearchProductsInitStatus)
  const selectedFilters = useSelector(selectSearchProductsSelectedFilters)

  useEffect(() => {
    dispatch(resetSearchProducts())

    if (searchQuery.length < 3) {
      dispatch(setInitStatus('NOTHING_TO_SEARCH'))

      return
    }

    fetchProducts({
      filter: createFilterDto({
        searchQuery
      }),
      size: pageSize,
      page: 1
    })
      .unwrap()
      .then((res) => {
        if (!res.products.length) {
          dispatch(setInitStatus('EMPTY_RESULT'))

          return
        }

        if (query && Object.keys(query).length <= 1) {
          dispatch(initSearchProducts(res))
          dispatch(setInitStatus('SUCCESS'))

          return
        }

        const { selectedFilters: selectedFiltersFromQuery } = getFiltersFromQuery({ query, res })

        dispatch(setDefaultFilters(res.facets))
        dispatch(updateAvailableFilters(res.facets))
        dispatch(updateSelectedFiltersFromQuery(selectedFiltersFromQuery))

        const filter = createFilterDtoFromSelectedFilters({
          selectedFilters: selectedFiltersFromQuery,
          searchQuery
        })

        fetchProducts({
          filter,
          size: pageSize,
          page: 1
        })
          .unwrap()
          .then((filteredRes) => {
            dispatch(updateAvailableFilters(filteredRes.facets))
            dispatch(setProducts(filteredRes))
          })
          .catch(() => {
            dispatch(initSearchProducts(res))
          })
          .finally(() => {
            dispatch(setInitStatus('SUCCESS'))
          })
      })
      .catch(() => {
        dispatch(setInitStatus('ERROR'))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- need to update only when query is changed
  }, [searchQuery])

  const isNextPageAvailable = useMemo(() => {
    const shownElements = products?.length || 0
    return shownElements < totalElements
  }, [products, totalElements])

  const loadMore = useCallback(() => {
    const params = {
      filter: createFilterDtoFromSelectedFilters({
        searchQuery,
        selectedFilters
      }),
      size: pageSize,
      page: page + 1
    } as GetSearchProductEndpointPayload

    void fetchProducts(params)
      .unwrap()
      .then((res) => {
        dispatch(addProducts(res.products))
        dispatch(setNextPage())
      })
  }, [dispatch, fetchProducts, page, pageSize, searchQuery, selectedFilters])

  return {
    products: products || [],
    totalElements,
    isNextPageAvailable,
    loadMore,
    isLoading,
    initStatus
  }
}
