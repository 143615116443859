import type { NextApiRequest, NextApiResponse } from 'next'
import type { ErrorRes, Language, ProductAutocompleteDTO } from 'ecosystem'
import storefrontApi from 'api/storefront-api'
import { useLocaleCredentials as setLocaleCredentials } from 'api'
import type { StorefrontSearchItem, StorefrontSearchRes } from '../types'

export const searchHandler = async (
  req: NextApiRequest,
  res: NextApiResponse<StorefrontSearchRes | ErrorRes>,
  productPath: string
) => {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  if (req.method !== 'GET') {
    res.status(405).json({ error: 'Method not supported.' })
    return
  }

  const { size, query } = req.query

  if (!query) {
    res.status(200).json({
      content: []
    })
    return
  }

  try {
    const limit = Number.isNaN(Number(size as string)) ? undefined : Number(size as string)
    const products = await storefrontApi.products.searchProductsAutocomplete(query as string, limit)

    const searchProducts: StorefrontSearchItem[] = products.map(
      (product: ProductAutocompleteDTO, index) => {
        return {
          id: `${product.slug}-${index}`,
          data: product,
          type: 'PRODUCT' as StorefrontSearchItem['type'],
          slug: `${productPath}/${product.slug}`,
          image: product.imageUrl,
          text: product.name
        }
      }
    )

    const resultContent = [...searchProducts]

    res.status(200).json({ content: resultContent })
  } catch (error) {
    res.status(404).json({ error: (error as Error).message })
  }
}
