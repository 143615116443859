import type { FC } from 'react'
import type { RangeSliderProps, StackProps } from '@chakra-ui/react'
import { HStack, VStack } from '@chakra-ui/react'
import { commaSeparator } from 'shared-utils'
import { GrandText } from 'ui'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { overrideText } from 'ui/lib/overrides'
import RangeFilterItem from '../../RangeFilterItem'

interface FilterRangeOverrides {
  filterRangeSelectorFrom?: string
  filterRangeSelectorTo?: string
}

interface IFilterPriceSelectorProps extends StackProps {
  unitsLabel: string
  rangeProps: RangeSliderProps
}

const FilterRangeSelector: FC<IFilterPriceSelectorProps> = ({
  unitsLabel,
  rangeProps: { value, defaultValue, ...rangeProps },
  ...props
}) => {
  const overrides = useOverridesContext<keyof FilterRangeOverrides>()
  const currentMinValue = commaSeparator((value || defaultValue)?.[0] ?? '')
  const currentMaxValue = commaSeparator((value || defaultValue)?.[1] ?? '')

  return (
    <HStack flexGrow={1} p={6} spacing={8} {...props}>
      <VStack flexShrink={0}>
        <GrandText fontSize="xs">
          {`${overrideText('från', overrides?.filterRangeSelectorFrom)} ${unitsLabel}`}
        </GrandText>
        <GrandText fontSize="xs">{currentMinValue}</GrandText>
      </VStack>
      <RangeFilterItem defaultValue={defaultValue} value={value} {...rangeProps} />
      <VStack flexShrink={0}>
        <GrandText fontSize="xs">
          {`${overrideText('upp till', overrides?.filterRangeSelectorTo)} ${unitsLabel}`}
        </GrandText>
        <GrandText fontSize="xs">{currentMaxValue}</GrandText>
      </VStack>
    </HStack>
  )
}

export default FilterRangeSelector
