import type { FC } from 'react'
import { useRef, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { BiSearch } from 'react-icons/bi'
import { Box, IconButton, SlideFade, useDisclosure, useOutsideClick } from '@chakra-ui/react'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { overrideText } from 'ui/lib/overrides'
import type { UseSearchReturnType } from '../hooks/useSearch'
import { SearchPanelInput } from './SearchPanelInput'
import { SearchResults } from './SearchResults'

type SearchPanelProps = UseSearchReturnType & {
  searchPagePath: string
}

export interface SearchPanelOverrides {
  searchPanelCloseSearch?: string
  searchPanelOpenSearch?: string
}

// is used for tablet and mobile view in header (TopNav)
export const SearchPanel: FC<SearchPanelProps> = ({
  data,
  clearSearch,
  onKeyDown,
  inputRef,
  searchQuery,
  isLoading,
  searchPagePath
}) => {
  const refWrapper = useRef(null)
  const { isOpen, onToggle } = useDisclosure()
  const [isShowResults, setIsShowResults] = useState(true)
  const overrides = useOverridesContext<keyof SearchPanelOverrides>()

  useOutsideClick({
    ref: refWrapper,
    handler: () => {
      setIsShowResults(false)
    }
  })

  const onToggleSearch = () => {
    if (isOpen) {
      clearSearch()
    }

    onToggle()
  }

  return (
    <Box
      className="search-panel"
      display={{
        base: 'block',
        lg: 'none'
      }}
      pl="2"
      position="relative"
      pr="1"
      ref={refWrapper}
      w="100%"
      zIndex="1">
      <SlideFade in={isOpen} offsetX="50%" offsetY="0" unmountOnExit>
        <SearchPanelInput
          isLoading={isLoading}
          onFocus={() => {
            setIsShowResults(true)
          }}
          onKeyDown={onKeyDown}
          ref={inputRef}
        />
      </SlideFade>

      <IconButton
        _focus={{
          outline: 'none'
        }}
        aria-label={
          isOpen
            ? overrideText('Öppna sökningen', overrides?.searchPanelCloseSearch)
            : overrideText('Stänga sökningen', overrides?.searchPanelOpenSearch)
        }
        background="#ECEFF4"
        borderRadius="40px 0 0 40px"
        fontSize="2xl"
        icon={isOpen ? <MdClose /> : <BiSearch />}
        minW="auto"
        onClick={onToggleSearch}
        p={2}
        pl={2}
        position="absolute"
        pr={5}
        right="0"
        shadow="none"
        size="md"
        top="2"
        variant="ghost"
      />

      {isShowResults ? (
        <SearchResults
          borderRadius="md"
          boxShadow="md"
          items={data}
          maxLength={5}
          mt={4}
          onClickResultItem={onToggleSearch}
          searchQuery={searchQuery}
          {...{ searchPagePath }}
        />
      ) : null}
    </Box>
  )
}
