'use client'
import { useCallback, useEffect, useRef } from 'react'
import {
  resetPage,
  resetSelectedFilters,
  selectSearchProductsAppliedSelectedFilters,
  selectSearchProductsAvailableFilters,
  selectSearchProductsSelectedFilters,
  setAppliedSelectedFilters,
  setProducts,
  setSelectedPriceRange,
  type TPriceRangeFilter,
  updateAvailableFilters,
  updateBrandFilterItemSelected as _updateBrandFilterItemSelected,
  updateCategoryFilterItemSelected as _updateCategoryFilterItemSelected,
  updateOptionFilterItemSelected as _updateOptionFilterItemSelected
} from 'shared-redux/state/slices/searchProducts'
import { useDispatch, useSelector } from 'shared-redux'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { usePrevious } from '@chakra-ui/react'
import deepEqual from 'deep-equal'
import type { GetSearchProductEndpointPayload } from 'shared-redux/state'
import { useLazyGetSearchProductsQuery } from 'shared-redux/state'
import { isEmpty, paramsFromObj } from 'shared-utils'
import type {
  SearchBrandFacetDTO,
  SearchCategoryFacetDTO,
  SearchOptionFacetDTO,
  SearchOptionValueFacetDTO
} from 'ecosystem'
import { PAGE_SIZE } from '../../constants'
import { createFilterDtoFromSelectedFilters } from './utils'

interface HookProps {
  pageSize?: number
}

export const useProductListSearchFilter = (props?: HookProps) => {
  const { pageSize = PAGE_SIZE } = props || {}
  const router = useRouter()
  const dispatch = useDispatch()
  const selectedFilters = useSelector(selectSearchProductsSelectedFilters)
  const appliedSelectedFilters = useSelector(selectSearchProductsAppliedSelectedFilters)
  const availableFilters = useSelector(selectSearchProductsAvailableFilters)
  const prevAppliedSelectedFilters = usePrevious(appliedSelectedFilters)

  const [fetchProducts, { isLoading }] = useLazyGetSearchProductsQuery()

  const searchParams = useSearchParams()
  const pathname = usePathname()
  const searchQuery = searchParams?.get('q') || ''

  const updateSearch = useCallback(() => {
    if (deepEqual(prevAppliedSelectedFilters, selectedFilters)) {
      return
    }

    const filter: GetSearchProductEndpointPayload['filter'] = createFilterDtoFromSelectedFilters({
      selectedFilters,
      searchQuery
    })

    const queryFilter: Record<string, string | string[]> = {
      q: filter.text,
      ...(filter.brandIds ? { brandIds: filter.brandIds } : {}),
      ...(filter.categoryIds ? { categoriesIds: filter.categoryIds } : {})
    }

    if (
      Number.isInteger(filter.minPrice) &&
      Number.isInteger(filter.maxPrice) &&
      (filter.minPrice !== availableFilters.minPrice ||
        filter.maxPrice !== availableFilters.maxPrice)
    ) {
      queryFilter.minPrice = String(filter.minPrice)
      queryFilter.maxPrice = String(filter.maxPrice)
    } else {
      delete filter.minPrice
      delete filter.maxPrice
    }

    if (!isEmpty(selectedFilters.options)) {
      Object.entries(selectedFilters.options).forEach(([key, value]) => {
        queryFilter[key] = value
      })
    }

    router.push(`${pathname}?${paramsFromObj(queryFilter)}`, {
      scroll: false
    })

    fetchProducts({
      size: pageSize,
      page: 1,
      filter
    })
      .unwrap()
      .then((res) => {
        dispatch(resetPage())
        dispatch(updateAvailableFilters(res.facets))
        dispatch(setProducts(res))
        dispatch(setAppliedSelectedFilters(selectedFilters))
      })
  }, [
    prevAppliedSelectedFilters,
    selectedFilters,
    searchQuery,
    availableFilters.minPrice,
    availableFilters.maxPrice,
    router,
    pathname,
    fetchProducts,
    pageSize,
    dispatch
  ])

  const updateSearchRef = useRef(updateSearch)

  useEffect(() => {
    updateSearchRef.current = updateSearch
  }, [updateSearch])

  const delayedRef = useRef<number>()
  const updateSearchDelayed = useCallback(() => {
    clearTimeout(delayedRef.current)

    delayedRef.current = setTimeout(() => {
      updateSearchRef.current()
    }, 300) as unknown as number
  }, [])

  const clearFilters = useCallback(() => {
    dispatch(resetSelectedFilters())

    router.push(`${pathname}?q=${searchQuery}`, {
      scroll: false
    })

    void fetchProducts({
      size: pageSize,
      page: 1,
      filter: {
        text: searchQuery
      }
    })
      .unwrap()
      .then((res) => {
        dispatch(resetPage())
        dispatch(updateAvailableFilters(res.facets))
        dispatch(setProducts(res))
      })
  }, [dispatch, router, pathname, searchQuery, fetchProducts, pageSize])

  const updateCategoryFilterItemSelected = useCallback(
    (payload: { id: SearchCategoryFacetDTO['id']; isSelected: boolean }) => {
      dispatch(_updateCategoryFilterItemSelected(payload))
    },
    [dispatch]
  )

  const updateBrandFilterItemSelected = useCallback(
    (payload: { id: SearchBrandFacetDTO['id']; isSelected: boolean }) => {
      dispatch(_updateBrandFilterItemSelected(payload))
    },
    [dispatch]
  )

  const updateSelectedPriceRange = useCallback(
    (payload: TPriceRangeFilter) => {
      dispatch(setSelectedPriceRange(payload))
    },
    [dispatch]
  )

  const updateOptionFilterItemSelected = useCallback(
    (payload: {
      id: SearchOptionValueFacetDTO['id']
      groupName: SearchOptionFacetDTO['name']
      isSelected: boolean
    }) => {
      dispatch(_updateOptionFilterItemSelected(payload))
    },
    [dispatch]
  )

  return {
    updateSearch,
    updateSearchDelayed,
    clearFilters,
    isLoading,
    updateCategoryFilterItemSelected,
    updateBrandFilterItemSelected,
    updateSelectedPriceRange,
    updateOptionFilterItemSelected
  }
}
