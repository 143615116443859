import { useCallback, useEffect } from 'react'
import { HStack, Input, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import { ContentContainer, GrandIconButton } from 'ui'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import type { UseSearchReturnType } from '../hooks/useSearch'
import { SearchResults } from './SearchResults'

interface SearchSlideOutProps extends UseSearchReturnType {
  onClear: () => void
  searchPagePath: string
}

export interface SearchSlideOutOverrides {
  searchPlaceholder?: string
}

export const SearchSlideOut = ({
  data,
  searchQuery,
  onClear,
  clearSearch,
  inputRef,
  onKeyDown,
  isLoading,
  searchPagePath
}: SearchSlideOutProps) => {
  const overrides = useOverridesContext<keyof SearchSlideOutOverrides>()

  const clear = useCallback(() => {
    clearSearch()
    onClear()
  }, [clearSearch, onClear])

  useEffect(() => {
    inputRef.current?.focus()
  }, [inputRef])

  return (
    <ContentContainer
      alignItems="flex-start"
      className="search-slide-out"
      direction="column"
      p="0"
      spacing="4"
      w="100%">
      <InputGroup w="100%">
        <Input
          _focus={{
            border: 'none',
            outline: 'none'
          }}
          _focusVisible={{
            border: 'none',
            outline: 'none'
          }}
          _hover={{
            border: 'none'
          }}
          _placeholder={{
            fontSize: 'xs'
          }}
          bg="background.default"
          border="none"
          borderRadius={0}
          fontWeight="medium"
          onKeyDown={onKeyDown}
          paddingInlineStart={6}
          paddingRight="65px"
          placeholder={overrideText('Sök efter produkt eller tjänst', overrides?.searchPlaceholder)}
          ref={inputRef}
        />

        <InputRightElement bg="transparent" border="none" p={0} w="auto">
          <HStack>
            {isLoading ? <Spinner color="text.default" opacity="0.7" size="xs" /> : null}

            <GrandIconButton
              aria-label="clear search"
              bg="transparent"
              boxShadow="none"
              color="text.default"
              icon={<MdClose />}
              onClick={clear}
              px={3}
            />
          </HStack>
        </InputRightElement>
      </InputGroup>

      <SearchResults
        borderRadius="md"
        items={data}
        maxLength={5}
        onClickResultItem={clear}
        searchQuery={searchQuery}
        w="100%"
        {...{ searchPagePath }}
      />
    </ContentContainer>
  )
}
