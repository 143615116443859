import { useEffect, useRef } from 'react'
import type { InputProps } from '@chakra-ui/react'
import { Input, InputGroup, InputRightElement, Spinner, forwardRef } from '@chakra-ui/react'
import { mergeRefs } from '@chakra-ui/react-utils'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'

type SearchPanelInputProps = InputProps & {
  isLoading?: boolean
}

export interface SearchPanelInputOverrides {
  searchPlaceholder?: string
}

export const SearchPanelInput = forwardRef<SearchPanelInputProps, 'input'>(
  ({ isLoading, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const overrides = useOverridesContext<keyof SearchPanelInputOverrides>()

    useEffect(() => {
      inputRef.current?.focus()
    }, [])

    return (
      <InputGroup>
        <Input
          _focus={{
            border: 'none'
          }}
          _focusVisible={{
            border: 'none'
          }}
          _placeholder={{
            fontSize: 'xs'
          }}
          background="background.default"
          border="none"
          borderBottomRightRadius="md"
          borderRadius="0"
          boxShadow="md"
          h="auto"
          pl={4}
          placeholder={overrideText('Sök efter produkt eller tjänst', overrides?.searchPlaceholder)}
          pr={isLoading ? 24 : 12}
          py={4}
          ref={mergeRefs(inputRef, ref)}
          {...props}
        />

        {isLoading ? (
          <InputRightElement h="100%" right={12} top={0}>
            <Spinner color="text.default" opacity={0.7} size="sm" />
          </InputRightElement>
        ) : null}
      </InputGroup>
    )
  }
)
