import type { FC } from 'react'
import { useMemo } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { Box, Button, HStack, Icon, Skeleton, Spacer, VStack } from '@chakra-ui/react'
import { BsSliders } from 'react-icons/bs'
import type {
  SearchProductsState,
  TPriceRangeFilter
} from 'shared-redux/state/slices/searchProducts'
import type { IAccordionItem } from 'ui'
import { GrandAccordion, GrandText } from 'ui'
import { overrideText } from 'ui/lib/overrides'
import type {
  SearchBrandFacetDTO,
  SearchCategoryFacetDTO,
  SearchOptionFacetDTO,
  SearchOptionValueFacetDTO
} from 'ecosystem'
import { Currency } from 'ecosystem'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import FilterRangeSelector from './ProductsFilter/FilterRangeSelector'
import FilterOptions from './ProductsFilter/FilterOptions'
import type { useProductListSearchFilter } from './useProductListSearchFilter'

interface ProductListSearchMoreFiltersOverrides {
  filterTitle?: string
  filterCategoriesTitle?: string
  filterBrandsTitle?: string
  filterPriceRangeTitle?: string
  filterClear?: string
  filterSubmitText?: string
  currency?: string
}

export type ProductListSearchMoreFiltersProps = StackProps &
  Omit<ReturnType<typeof useProductListSearchFilter>, 'updateSearch'> & {
    searchProductsState: Pick<
      SearchProductsState,
      'availableFilters' | 'selectedFilters' | 'totalElements' | 'initStatus'
    >
    onSubmit?: () => void
    showSubmit?: boolean
    updateCategoryFilterItemSelected: (payload: {
      id: SearchCategoryFacetDTO['id']
      isSelected: boolean
    }) => void
    updateBrandFilterItemSelected: (payload: {
      id: SearchBrandFacetDTO['id']
      isSelected: boolean
    }) => void
    updateSelectedPriceRange: (payload: TPriceRangeFilter) => void
    updateOptionFilterItemSelected: (payload: {
      id: SearchOptionValueFacetDTO['id']
      groupName: SearchOptionFacetDTO['name']
      isSelected: boolean
    }) => void
  }

export const ProductListSearchMoreFilters: FC<ProductListSearchMoreFiltersProps> = ({
  showSubmit,
  onSubmit,
  searchProductsState,
  updateSearchDelayed,
  isLoading,
  clearFilters,
  updateCategoryFilterItemSelected,
  updateBrandFilterItemSelected,
  updateSelectedPriceRange,
  updateOptionFilterItemSelected,
  ...props
}) => {
  const overrides = useOverridesContext<keyof ProductListSearchMoreFiltersOverrides>()
  const { availableFilters, selectedFilters, totalElements, initStatus } = searchProductsState
  const isInit = initStatus === 'SUCCESS'

  const availablePriceRange = useMemo(
    () =>
      Number.isInteger(availableFilters.minPrice) && Number.isInteger(availableFilters.maxPrice)
        ? ([availableFilters.minPrice, availableFilters.maxPrice] as [number, number])
        : undefined,
    [availableFilters.maxPrice, availableFilters.minPrice]
  )

  const selectedPriceRange = useMemo(
    () =>
      Number.isInteger(selectedFilters.minPrice) && Number.isInteger(selectedFilters.maxPrice)
        ? ([selectedFilters.minPrice, selectedFilters.maxPrice] as [number, number])
        : undefined,
    [selectedFilters.maxPrice, selectedFilters.minPrice]
  )

  const handleSubmitButton = () => {
    onSubmit?.()
  }

  // ********************** VIEW CONFIG ***********************
  const accordionItems: IAccordionItem[] = useMemo(() => {
    const facetFilters = availableFilters.options.map((group) => ({
      id: group.name,
      title: group.name,
      component: (
        <FilterOptions
          onChange={({ id, isSelected }) => {
            updateOptionFilterItemSelected({ id, isSelected, groupName: group.name })
            updateSearchDelayed()
          }}
          options={group.values.map(({ id, value: name, count }) => ({ id, name, count }))}
          selectedOptions={selectedFilters.options[group.name] || []}
        />
      )
    }))

    return [
      ...(availableFilters.categories?.length
        ? [
            {
              id: 'categories',
              title: overrideText('Kategorier', overrides?.filterCategoriesTitle),
              component: (
                <FilterOptions
                  onChange={({ id, isSelected }) => {
                    updateCategoryFilterItemSelected({ id, isSelected })
                    updateSearchDelayed()
                  }}
                  options={availableFilters.categories}
                  selectedOptions={selectedFilters.categories}
                />
              )
            }
          ]
        : []),
      ...(availableFilters.brands?.length
        ? [
            {
              id: 'brands',
              title: overrideText('Varumärken', overrides?.filterBrandsTitle),
              component: (
                <FilterOptions
                  onChange={({ id, isSelected }) => {
                    updateBrandFilterItemSelected({ id, isSelected })
                    updateSearchDelayed()
                  }}
                  options={availableFilters.brands}
                  selectedOptions={selectedFilters.brands}
                />
              )
            }
          ]
        : []),
      ...facetFilters,
      ...(availablePriceRange && availablePriceRange[0] !== availablePriceRange[1]
        ? [
            {
              id: 'price',
              title: overrideText('Pris', overrides?.filterPriceRangeTitle),
              component: (
                <FilterRangeSelector
                  px={0}
                  rangeProps={{
                    value: selectedPriceRange,
                    defaultValue: availablePriceRange,
                    min: availablePriceRange[0],
                    max: availablePriceRange[1],
                    onChange: (values) => {
                      updateSelectedPriceRange(values as [number, number])
                      updateSearchDelayed()
                    }
                  }}
                  unitsLabel={overrideText(Currency.SEK, overrides?.currency)}
                  w="full"
                />
              )
            }
          ]
        : [])
    ]
  }, [
    availableFilters.options,
    availableFilters.categories,
    availableFilters.brands,
    overrides?.filterCategoriesTitle,
    overrides?.filterBrandsTitle,
    overrides?.filterPriceRangeTitle,
    overrides?.currency,
    selectedFilters.categories,
    selectedFilters.brands,
    selectedFilters.options,
    availablePriceRange,
    selectedPriceRange,
    updateOptionFilterItemSelected,
    updateSearchDelayed,
    updateCategoryFilterItemSelected,
    updateBrandFilterItemSelected,
    updateSelectedPriceRange
  ])

  if (!isInit) {
    return (
      <VStack alignItems="stretch" gap={4} h="full" w="full" {...props}>
        <HStack justifyContent="space-between">
          <Skeleton h="20px" w="65%" />
          <Skeleton h="20px" w="30%" />
        </HStack>

        <VStack alignItems="stretch" w="100%">
          <Skeleton h="30px" />
          <Skeleton h="30px" />
          <Skeleton h="30px" />
        </VStack>
      </VStack>
    )
  }

  return (
    <VStack h="full" justify="space-between" spacing={10} w="full" {...props}>
      <VStack spacing={6} w="full">
        <HStack justify="flex-end" px={3} w="full">
          <HStack spacing={2}>
            <Icon as={BsSliders} />
            <GrandText>{overrideText('Filter', overrides?.filterTitle)}</GrandText>
          </HStack>
          <Spacer />
          <Button
            _focus={{
              boxShadow: 'none',
              outline: 'none'
            }}
            _hover={{
              bg: 'transparent'
            }}
            aria-label={overrideText('Återställ', overrides?.filterClear)}
            color="text.default"
            fontSize="sm"
            fontWeight="sm"
            onClick={clearFilters}
            size="xs"
            textDecor="underline"
            variant="ghost">
            {overrideText('Återställ', overrides?.filterClear)}
          </Button>
        </HStack>

        <Box w="100%">
          {accordionItems.map((item) => (
            <GrandAccordion
              allowMultiple
              buttonProps={{
                fontSize: 'md',
                textDecor: 'underline',
                fontWeight: 'medium'
              }}
              items={[item]}
              key={item.id}
              mb="-1px"
              p={0}
              w="full"
            />
          ))}
        </Box>
      </VStack>

      {!!showSubmit && (
        <VStack align="flex-end" w="full">
          <Button
            aria-label={overrideText(
              `Visa ${totalElements} produkter`,
              overrides?.filterSubmitText
            )}
            bg="primary"
            color="text.light"
            onClick={handleSubmitButton}
            size="sm"
            w="full"
            {...{ isLoading }}>
            {overrideText(
              `Visa ${totalElements} produkter`,
              overrides?.filterSubmitText && `${overrides?.filterSubmitText}: ${totalElements}`
            )}
          </Button>
        </VStack>
      )}
    </VStack>
  )
}
