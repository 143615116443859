import { ListItem, UnorderedList } from '@chakra-ui/react'
import { GrandSection } from 'ui'

interface SearchPageEmptyProps {
  searchQuery?: string
  language?: 'sv' | 'en'
}

export const SearchPageEmptyResult = ({
  searchQuery = '',
  language = 'sv'
}: SearchPageEmptyProps) => {
  if (language === 'sv') {
    return (
      <GrandSection
        sectionId="searchPage"
        title={`Inga resultat${
          searchQuery.length ? ` för «${searchQuery}»` : ''
        }. Försök igen, eller se förslag nedan.`}>
        <UnorderedList>
          <ListItem>
            Du kan t.ex. söka efter produktnamn, produktkod, produkttyp, kategori eller varumärke.
          </ListItem>
          <ListItem>
            När du skriver i sökfältet rekommenderar systemet söktermer medan du skriver. Detta för
            att hjälpa dig hitta rätt produkt.
          </ListItem>
          <ListItem>Använd menyn för produktkategori för att hitta produkter.</ListItem>
        </UnorderedList>
      </GrandSection>
    )
  }

  return (
    <GrandSection
      sectionId="searchPage"
      title={`No results${
        searchQuery.length ? ` for «${searchQuery}»` : ''
      }. Try again, or see suggestions below.`}>
      <UnorderedList>
        <ListItem>
          You can e.g. search by product name, product code, product type, category or brand.
        </ListItem>
        <ListItem>
          As you type in the search field, the system recommends search terms as you type. This is
          to help you find the right product.
        </ListItem>
        <ListItem>Use the product category menu to find products.</ListItem>
      </UnorderedList>
    </GrandSection>
  )
}
